'use client';
import {DASHBOARD_ROUTES} from '@/shared/utils/paths';
import {useRouter} from 'next/navigation';
import {useEffect} from 'react';

export default function RedirectToDashboard() {
  const router = useRouter();
  useEffect(() => {
    router.push(DASHBOARD_ROUTES.home);
  }, []);
}
